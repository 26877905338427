import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse } from 'axios'

interface OutletListState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;

  lastMeta: {
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };

  documents: any[];
}

const $state: OutletListState = {
  loading: false,
  loaded: false,
  fatalError: false,

  lastMeta: {
    page: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },

  documents: [],
}

export const $actions: ActionTree<OutletListState, RootState> = {
  loadData({ commit, getters, dispatch }, { query, merchant_inn }) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      const params: any = {}

      if (query.sortField) {
        params.order_by = query.sortField
        params.order_direction = query.sortDirection || 'asc'
      }

      params.page = query.page || 1
      params.merchant_inn = merchant_inn
      params.per_page = 1000

      if (query.name) {
        params.search = query.name
      }

      commit('START_LOADING')

      vm.$http.all([
        vm.$http.get('/admins/rest/v1/outlets', { params }),
        vm.$http.get('/admins/rest/v1/receipts', { params }),
      ]).then((responses: AxiosResponse[]) => {
        const outlets = responses[0].data
        const receipts = responses[1].data.receipts

        console.log('Received ' + receipts.length + ' receipts.')

        for (let i = 0; i < outlets.outlets.length; i++) {
          let receipts_count = 0
          for (let k = 0; k < receipts.length; k++) {
            if (receipts[k].outlet_id === outlets.outlets[i].id) {
              receipts_count++
            }
          }

          outlets.outlets[i].receipts_count = '' + receipts_count

          if (outlets.outlets[i].address === null) {
            outlets.outlets[i].address = '(Не указан)'
          }
          else if (outlets.outlets[i].address.length === 0) {
            outlets.outlets[i].address = '(Не указан)'
          }
        }


        commit('SET_ITEMS_LIST', outlets)
        commit('LOADED_SUCCESS')

        resolve(null)
      })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
          // reject(error.response?.data);
        })
    })
  },

}

export const $mutations: MutationTree<OutletListState> = {
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },

  SET_ITEMS_LIST(state, data) {
    state.documents = data?.outlets || data

    state.lastMeta = {
      page: data?.meta?.page,
      pageSize: data?.meta?.per_page,
      totalCount: data?.meta?.total_count,
      totalPages: data?.meta?.total_pages,
    }
  },
}

export const $getters: GetterTree<OutletListState, RootState> = {}

export const STORE_KEY = '$_outlet_list'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
