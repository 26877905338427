

















































































































































































































































































































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, sameAs } from 'vuelidate/lib/validators'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'
import ComboboxSelectedObserver from '@/components/Controls/ComboboxSelectedObserver.vue'
import OutletList from '@/components/OutletList/OutletList.vue'

@Component({
  mixins: [validationMixin],
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'fatalError', 'serverErrors',
      'form', 'formDirty',
    ]),
  },
  validations() {
    const rules: any = {
      id: {},

      brand_id: {},
      type: { required },
      category_id: { required },
      chief_name: {},
      chief_phone: {},
      city_id: {},
      franchise_contract_upload_id: {},
      inn: { required },
      inn_upload_id: {},
      kpp: {},
      kpp_upload_id: {},
      legal_address: {},
      logo_upload_id: {},
      name: { required },
      parent_id: {},
      trade_type: {},
      contacts: [],
      item_sum: {},
      allow_accural_auto_start: {},
      allow_accural_manual_start: {},
      can_request_product_feedback: {},
      can_request_receipt_feedback: {},

      requisites_ogrn: {},
      requisites_ogrn_date: {},
      requisites_okpo: {},
      requisites_payment_account: {},
      requisites_correspondent_account: {},
      requisites_bik: {},
      requisites_bank_name: {},
      requisites_actual_address: {},
      requisites_tax_type_id: {},

      ecp_operator_name: {},
      ecp_phone_number: {},
      ecp_manager_name: {},
      ecp_email: {},

      chief_phone_confirmed: {},
      chief_phone_confirmation_allowed: {},
      chief_phone_confirmation_rejected: {},
      chief_phone_confirmation_token: {},
      chief_phone_confirmation_code: {},
    }

    return {
      form: rules,
    }
  },
  components: {
    TitleBar,
    ComboboxSelectedObserver,
    OutletList,
  },
})
export default class MerchantObserve extends Vue {

  public merchants_types: any = {
    producer: { name: 'Производитель' },
    merchant: { name: 'Магазин' },
    producer_merchant: { name: 'Производитель-Магазин' },
  }

  get isNew() {
    return !Number.isInteger(Number(this.$route.params.id))
  }

  get titleStack() {
    return [
      'Организации', 'Информация об организации',
    ]
  }

  get active_tab() {
    if ('active_tab' in this.$route.params) {
      return this.$route.params.active_tab
    }

    return 'main'
  }

  /*
   *  2022-04-07  -->  07.04.2022
   */
  public format_date(date: string) {
    const parts = date.split('-')
    return parts[2] + '.' + parts[1] + '.' + parts[0]
  }

  public getContact(type: string) {
    // @ts-ignore
    if (!this.form.contacts) {
      return {
        value: '',
        is_public: true,
      }
    }
    // @ts-ignore
    for (const contact of this.form.contacts) {
      if (contact.type === type) {
        return {
          value: contact.value,
          is_public: contact.is_public,
        }
      }
    }
    return {
      value: '',
      is_public: true,
    }
  }

  public confirmPhoneNumber() {
    // @ts-ignore
    this.$store.dispatch(`${STORE_KEY}/confirmPhoneNumber`, this.form.chief_phone)
      .then((result) => {
        this.$forceUpdate()
      }).catch((error) => {
      this.$forceUpdate()
    })
  }

  public confirmPhoneNumberAttempt() {
    // @ts-ignore
    if (this.form.chief_phone_confirmation_code !== null) {
      // @ts-ignore
      this.changeValue('chief_phone_confirmation_attempts', this.form.chief_phone_confirmation_attempts - 1)

      // @ts-ignore
      this.$store.dispatch(`${STORE_KEY}/confirmPhoneNumberAttempt`, {
        // @ts-ignore
        token: this.form.chief_phone_confirmation_token,
        // @ts-ignore
        code: this.form.chief_phone_confirmation_code,
      })
        .then((result) => {
          this.$forceUpdate()
        }).catch((error) => {
        this.$forceUpdate()
      })

      // @ts-ignore
      if ((this.form.chief_phone_confirmation_attempts === 0) && (!this.form.chief_phone_confirmed)) {
        this.changeValue('chief_phone_confirmation_allowed', false)
      }
    }
  }


  public changeValue(field: string, value: any) {
    this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
      field, newValue: value,
    })

    // @ts-ignore
    // this.$v.form[field].$touch();
  }


  public EditMainButtonClick() {
    this.$v.form.$touch()
    // @ts-ignore
    this.$router.push({ name: 'merchant-edit', params: { id: this.form.id, return_to_observe: true } })
  }

  public EditCredentialsButtonClick() {
    this.$v.form.$touch()
    // @ts-ignore
    this.$router.push({ name: 'merchant-edit-credentials', params: { id: this.form.id, return_to_observe: true } })
  }

  public EditEcpButtonClick() {
    this.$v.form.$touch()
    // @ts-ignore
    this.$router.push({ name: 'merchant-edit-ecp', params: { id: this.form.id, return_to_observe: true } })
  }

  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.$store.dispatch(`${STORE_KEY}/initState`, {
      id: this.$route.params.id,
      predefined: this.$route.query,
    })
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }
}
