import { ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import axios from 'axios';


interface MerchantFormObserverState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  serverErrors: any;

  object: any;
  form: any;
}

const $state: MerchantFormObserverState = {
  loading: false,
  loaded: false,
  fatalError: false,
  serverErrors: null,

  object: null,
  form: null,
}


const initState = cloneDeep($state)


const EMPTY_OBJECT = {
  id: null,

  brand_id: null,
  category_id: null,
  chief_name: null,
  chief_phone: null,
  city_id: null,
  franchise_contract_upload_id: null,
  inn: null,
  inn_upload_id: null,
  kpp: null,
  kpp_upload_id: null,
  legal_address: null,
  logo_upload_id: null,
  name: null,
  parent_id: null,
  trade_type: null,
  contacts: null,
  item_sum: 0,
  accural_start: 'auto',
  allow_accural_auto_start: true,
  allow_accural_manual_start: true,
  type: '',
  feedback_merchant: false,
  mark_merchant: false,
  feedback_product: false,
  mark_product: false,
  can_request_product_feedback: true,
  can_request_receipt_feedback: true,

  requisites_ogrn: null,
  requisites_ogrn_date: null,
  requisites_okpo: null,
  requisites_payment_account: null,
  requisites_correspondent_account: null,
  requisites_bik: null,
  requisites_bank_name: null,
  requisites_actual_address: null,
  requisites_tax_type_id: null,

  ecp_operator_name: null,
  ecp_phone_number: null,
  ecp_manager_name: null,
  ecp_email: null,

  chief_phone_confirmed: false,
  chief_phone_confirmation_allowed: false,
  chief_phone_confirmation_attempts: 3,
  chief_phone_confirmation_rejected: false,
  chief_phone_confirmation_token: null,
  chief_phone_confirmation_code: null,
}

const AVAILABLE_FIELDS = [
  'id', 'brand_id', 'category_id', 'chief_name', 'chief_phone', 'city_id', 'franchise_contract_upload_id',
  'inn', 'inn_upload_id', 'kpp', 'kpp_upload_id', 'legal_address', 'logo_upload_id', 'name',
  'parent_id', 'trade_type', 'contacts', 'item_sum', 'accural_start', 'allow_accural_auto_start',
  'allow_accural_manual_start', 'feedback_merchant', 'mark_merchant', 'feedback_product',
  'mark_product', 'type', 'can_request_product_feedback', 'can_request_receipt_feedback',
  'requisites_ogrn', 'requisites_ogrn_date', 'requisites_okpo', 'requisites_payment_account',
  'requisites_correspondent_account', 'requisites_bik', 'requisites_bank_name',
  'requisites_actual_address', 'requisites_tax_type_id',

  'ecp_operator_name', 'ecp_phone_number', 'ecp_manager_name', 'ecp_email',

  'chief_phone_confirmed', 'chief_phone_confirmation_allowed', 'chief_phone_confirmation_attempts',
  'chief_phone_confirmation_rejected', 'chief_phone_confirmation_token', 'chief_phone_confirmation_code',
]

function check_phone(phone: string) {
  return phone.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)
}

function transform_phone(phone: string) {
  if (phone === null) {
    return phone
  }

  phone = phone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '')
  if ((phone.length > 5) && check_phone(phone)) {
    if (phone.charAt(0) === '+') {
      phone = '' + (Number(phone[1]) + 1) + phone.substring(2)
    }
  }

  return phone
}

export const $actions: ActionTree<MerchantFormObserverState, RootState> = {

  async initState({ state, commit }, { id, predefined }) {
    try {
      commit('START_LOADING')
      const response = await axios.get(`/admins/rest/v1/merchants/${id}`);

      commit('SET_FORM_DATA', { object: response.data.merchant })
      commit('LOADED_SUCCESS')

    } catch (error) {

      console.error('Fatal error', error.data)
      commit('LOADED_ERROR')

    }
  },

  // initState({ commit, state }, { id, predefined }) {
  //   return new Promise((resolve, reject) => {
  //     if (id) {
  //       const vm = (this as any)._vm
  //       commit('START_LOADING')
  //       vm.$http.get(`/admins/rest/v1/merchants/${id}`)
  //         .then((response: AxiosResponse) => {
  //           const form_data = response.data.merchant
  //
  //           const params: any = {}
  //           params.phone = transform_phone(form_data.chief_phone)
  //           commit('SET_FORM_DATA', {
  //             object: form_data,
  //           })
  //           commit('LOADED_SUCCESS')
  //           // vm.$http.get(`/admins/rest/v1/credentials/check`, { params })
  //           //   .then((check_response: AxiosResponse) => {
  //           //     console.log('Checked if phone confirmed!')
  //           //     console.log(check_response.data)
  //           //
  //           //     form_data.chief_phone_confirmed = false
  //           //
  //           //     if (check_response.data.credentials_exists) {
  //           //       form_data.chief_phone_confirmed = check_response.data.credentials.phone_confirmed && (check_response.data.credentials.owner_id === form_data.id)
  //           //     }
  //           //
  //           //     commit('SET_FORM_DATA', {
  //           //       object: form_data,
  //           //     })
  //           //
  //           //     resolve(state.object)
  //           //   })
  //           //   .catch((error: AxiosError) => {
  //           //     console.log('Check phone confirmed error!')
  //           //     form_data.chief_phone_confirmed = false
  //           //
  //           //     commit('SET_FORM_DATA', {
  //           //       object: form_data,
  //           //     })
  //           //     commit('LOADED_SUCCESS')
  //           //
  //           //     resolve(state.object)
  //           //   })
  //
  //         })
  //         .catch((error: AxiosError) => {
  //           // tslint:disable-next-line:no-console
  //           console.error('Fatal error', error.response?.data)
  //           commit('LOADED_ERROR')
  //           reject(error.response?.data)
  //         })
  //     }
  //     else {
  //       return new Promise((resolve, reject) => {
  //         commit('GENERATE_FORM_DATA', predefined)
  //         commit('LOADED_SUCCESS')
  //         resolve(state.object)
  //       })
  //     }
  //   })
  // },

  prepareNew({ commit, state }, predefined) {
    return new Promise((resolve, reject) => {
      commit('GENERATE_FORM_DATA', predefined)
      commit('LOADED_SUCCESS')
      resolve(null)
    })
  },

  changeFormValue({ commit }, { field, newValue }) {
    commit('CHANGE_VALUE', { field, newValue })
  },

  confirmPhoneNumber({ commit, state }, phone_number) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      const params: any = {}
      params.owner_id = state.form.id
      params.phone = transform_phone(phone_number)
      vm.$http.get(`/admins/rest/v1/credentials/confirm`, { params })
        .then((response: AxiosResponse) => {
          console.log(response.data)

          commit('CHANGE_VALUE', { field: 'chief_phone_confirmation_code', newValue: null })
          commit('CHANGE_VALUE', { field: 'chief_phone_confirmation_allowed', newValue: true })
          commit('CHANGE_VALUE', { field: 'chief_phone_confirmation_attempts', newValue: 3 })
          commit('CHANGE_VALUE', { field: 'chief_phone_confirmation_rejected', newValue: false })
          commit('CHANGE_VALUE', {
            field: 'chief_phone_confirmation_token',
            newValue: response.data.confirmation_token,
          })

          resolve(null)
        })
        .catch((error: AxiosError) => {
          commit('CHANGE_VALUE', { field: 'chief_phone_confirmation_rejected', newValue: true })
          reject(null)
        })
    })
  },

  confirmPhoneNumberAttempt({ commit, state }, { token, code }) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      const params: any = {}
      params.chief_name = state.form.chief_name
      params.token = token
      params.code = code
      vm.$http.get(`/admins/rest/v1/credentials/checkcode`, { params })
        .then((response: AxiosResponse) => {
          console.log(response.data)

          let confirmed = false
          if (('phone_confirmed' in response.data.credentials) && ('owner_id' in response.data.credentials)) {
            confirmed = response.data.credentials.phone_confirmed && (response.data.credentials.owner_id === state.form.id)
          }

          if (confirmed) {
            commit('CHANGE_VALUE', { field: 'chief_phone_confirmed', newValue: true })
            commit('CHANGE_VALUE', { field: 'chief_phone_confirmation_allowed', newValue: false })
            commit('CHANGE_VALUE', { field: 'chief_phone_confirmation_rejected', newValue: false })
          }
          else {
            /*commit('CHANGE_VALUE', {
                field: 'chief_phone_confirmation_attempts',
                newValue: state.form.hief_phone_confirmation_attempts - 1
            }); */
            // commit('CHANGE_VALUE', {field: 'chief_phone_confirmation_allowed', newValue: false});

          }

          resolve(null)
        })
        .catch((error: AxiosError) => {
          commit('CHANGE_VALUE', { field: 'chief_phone_confirmation_rejected', newValue: true })
          reject(null)
        })
    })
  },
}

export const $mutations: MutationTree<MerchantFormObserverState> = {
  RESET_STATE: (state) => {
    for (const key in initState) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },
  CHANGE_VALUE(state: MerchantFormObserverState, { field, newValue }) {
    state.form[field] = newValue
  },

  SET_FORM_DATA(state, { object }) {
    state.object = object

    const formData = cloneDeep(state.object)

    state.form = {
      ...formData,
    }

    state.form.requisites_actual_address_tooltip_value = {}
  },

  GENERATE_FORM_DATA(state, predefined) {
    state.form = {
      ...cloneDeep(EMPTY_OBJECT),
      ...predefined,
    }
  },
}

const $getters = {}

export const STORE_KEY = '$_merchant_form_observer'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
};

