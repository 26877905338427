

































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapState, mapActions } from 'vuex'

import { STORE_KEY, store } from './_store'

@Component({
  computed: {
    ...mapState(STORE_KEY, [
      'loading', 'loaded', 'fatalError',

      'filter',

      'documents', 'lastMeta',
    ]),
  },
  methods: {
    ...mapActions(STORE_KEY, [
      'exportData', 'loadData',
    ]),
  },
  components: {},
})
export default class OutletList extends Vue {
  @Prop({ type: Number }) public merchant_id!: number
  @Prop({ type: String }) public merchant_inn!: string
  @Prop() public outletList?: object

  get titleStack() {
    return [
      'Точки продаж',
    ]
  }

  public getQueryParam(field: string) {
    return this.$route.query ? cloneDeep(this.$route.query[field]) : null
  }

  public tableClick(row: any) {
    this.$router.push({ name: 'outlet-edit', params: { id: row.id, from_observer: row.merchant_id } })
  }
  public openCheckViewing(outlets: any) {
    this.$router.push({ name: 'report-receipts', params: { for_outlets: outlets } })
  }
  public openViewingReviews(row: any) {
    this.$router.push({ name: 'brand-comment', params: { id: row.brand_id } })
  }

  // ---------------------------------------------------------------------------

  get defaultSort() {
    return [this.$route.query.sortField || 'name', this.$route.query.sortDirection || 'asc']
  }

  public onSort(field: string, direction: string) {
    this.$router.replace({
      query: {
        ...this.$route.query, sortField: field, sortDirection: direction,
      },
    })
  }

  public onSearch(filter: string, value: any) {
    this.$router.replace({
      query: {
        ...this.$route.query, [filter]: value,
      },
    })
  }

  public onPageChange(page: string) {
    this.$router.replace({
      query: { ...this.$route.query, page },
    })
  }

  // ---------------------------------------------------------------------------

  // @Watch('$route.query')
  // @Watch('merchant_id')
  @Watch('merchant_inn')
  public handleParamsChange() {
    if (this.outletList) {
      this.$store.commit(`${STORE_KEY}/SET_ITEMS_LIST`, this.outletList)
    } else {
      this.$store.dispatch(`${STORE_KEY}/loadData`, { query: this.$route.query, merchant_inn: this.merchant_inn })
    }
  }

  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.handleParamsChange()
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }

}
